import React, {useEffect} from 'react';

import { graphql, useStaticQuery } from "gatsby"

import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Img from "gatsby-image"

import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import styled from "styled-components";
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import MovieIcon from '@material-ui/icons/Movie';
import StoreIcon from '@material-ui/icons/Store';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import InfoIcon from '@material-ui/icons/Info';
import MenuIcon from '@material-ui/icons/Menu';
import TvIcon from '@material-ui/icons/Tv';

import ReactCountryFlag from "react-country-flag"

import {menuColour} from './theme';


const MenuButton = styled.button`
  position: absolute;
  display: inline-block;
  position: absolute;
  pointer-events: auto;
  z-index: 20;
  left: 0;
  top: 8.75rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ffd901;
  font-size: 24px;
  transform: scale(0.7);
  transition: all 350ms ease-in-out;
  outline: none;
  user-select: none;
  border: none;
  
  &:hover {
    transform: scale(1);
  }
`

const useStyles = makeStyles( theme=>({
  leftSideBar: {
    position: 'fixed',
    top: 0,
    minHeight: '100vh',
    width: 55,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundColor: 'white',
    background: 'linear-gradient(to bottom, #ffffff 20%,#ffd901 100%)',
    background: '#ffd901',
    paddingTop: 5,
    zIndex: 1400,
  },
  
  leftSideBarInner: {
    display: 'flex',
  },
  menuListItem: {
    height: 68,
    width: 55,
    paddingLeft: 0,
    paddingRight: 0,
    
  },
  menuListIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  listContainer: {
    // width: 350,
    height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-evenly',
  },
  list: {
    color: 'black',
    fontWeight: 700,
    fontSize: '1.5rem',
    // marginTop: 130,
    marginTop: 118,
  },
  listItem: {
    fontSize: '1.5rem',
    fontFamily: "'futura-pt', sans-serif",
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
      backgroundColor: 'rgb(255, 217, 1)',
      display: 'flex',
      justifyContent: 'center',
      left: 55,
      overflow: 'hidden',
  },
  buttonRoot: {
    padding: 0,
    width: 50,
    minWidth: 50,
    marginTop: 15,
    
  },
  icon: {
    color: 'rgba(21,21,21,1)',
  },
  menuLink: {
    textDecoration: 'none',
    color: '#000',
  },
  
  languages: {
    position: 'absolute',
    bottom: 10,
    marginLeft: 0
  },
  
  language: {
    listStyle: 'none'
  }

}));

export default function TemporaryDrawer(props) {
    
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "FILM_POSITIVE_logo_web_camera_only_01.png"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

   
  const {languages, originalPath, t} = useI18next();
  const context = React.useContext(I18nextContext);
  const language = context.language;
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.listContainer, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.list} onMouseLeave={toggleDrawer('left', false)}>
          <ListItem button>
            <Link to="/reklamok" className={classes.menuLink}><ListItemText classes={{primary: classes.listItem}} primary={t("MENU.COMMERCIALS")} secondary="" /></Link>
          </ListItem>
          <ListItem button>
            <Link to="/filmek" className={classes.menuLink}><ListItemText classes={{primary: classes.listItem}} primary={t("MENU.MOVIES")} secondary="" /></Link>
          </ListItem>
          { (language == "en") &&
            <ListItem button>
              <Link to="/shooting-in-budapest" className={classes.menuLink}><ListItemText classes={{primary: classes.listItem}} primary="Shooting in Budapest" secondary="" /></Link>
            </ListItem>
          }
          <ListItem button>
            <Link to="/rolunk" className={classes.menuLink}><ListItemText classes={{primary: classes.listItem}} primary={t("MENU.ABOUT_US")} secondary="" /></Link>
          </ListItem>
          {/*<ListItem button>
            <ListItemText classes={{primary: classes.listItem}} primary="Contact" secondary="" />
          </ListItem>*/}

      </List>
      {/*<Divider />
      <List>
          <ListItem button >
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary="Teszt 2" />
          </ListItem>
      </List>*/}
    </div>
  );

  return (
        <React.Fragment>
            <Box className={classes.leftSideBar} >
              <Link to="/"><Img fixed={data.file.childImageSharp.fixed} /></Link>
              <List onClick={toggleDrawer('left', !state.left)} onMouseOver={toggleDrawer('left', true)} className={classes.leftSideBarInner}>
                  <div>
                  <ListItem className={classes.menuListItem} button>
                    <ListItemIcon className={classes.menuListIcon}><MenuIcon classes={{root: classes.icon}} /></ListItemIcon>
                  </ListItem>
                  
                  
                  <ListItem className={classes.menuListItem} button>
                    <ListItemIcon className={classes.menuListIcon}><TvIcon classes={{root: classes.icon}} /></ListItemIcon>
                  </ListItem>
                  
                  <ListItem className={classes.menuListItem} button>
                    <ListItemIcon className={classes.menuListIcon}><LocalMoviesIcon classes={{root: classes.icon}} /></ListItemIcon>
                  </ListItem>
                  { (language == "en") &&
                    <ListItem className={classes.menuListItem} button>
                      <ListItemIcon className={classes.menuListIcon}><MovieIcon classes={{root: classes.icon}} /></ListItemIcon>
                    </ListItem>
                  }
                  <ListItem className={classes.menuListItem} button>
                    <ListItemIcon className={classes.menuListIcon}><InfoIcon classes={{root: classes.icon}} /></ListItemIcon>
                  </ListItem>
                  
                  {/*<ListItem className={classes.menuListItem} button>
                    <ListItemIcon className={classes.menuListIcon}><ContactMailIcon classes={{root: classes.icon}} /></ListItemIcon>
                  </ListItem>*/}
                  </div>
              
              </List>
              
                  <ul className={classes.languages}>
                    {languages.map((lng) => (
                      <li key={lng} className={classes.language}>
                        <Link to={originalPath} language={lng}>
                          <ReactCountryFlag
                              className="emojiFlag"
                              countryCode={lng == 'en' ? 'gb' : lng.toUpperCase()}
                              style={{
                                  fontSize: '1.5em',
                                  lineHeight: '1.5em',
                              }}
                              aria-label={lng}
                              svg
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
            </Box>
          {/*<MenuButton onClick={toggleDrawer('left', true)}>{'left'}</MenuButton>*/}
          <Drawer classes={{paperAnchorLeft: classes.drawer}} bgcolor="primary" anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
        </React.Fragment>
  );
}