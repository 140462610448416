import React from 'react'
import {
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next"

import styled from 'styled-components';
import styles from './footer.module.scss';

import filmpositiveLogo from '../images/film-positive-71x50.png';
import filmpositiveserviceLogo from '../images/film-positive-service-50h.png';
import szupermodernLogo from '../images/szupermodern-logo-217x50.png';
import crossdotLogo from '../images/crossdot-logo-white-blue-85x50.png';

const Footer = () => {
    const {t} = useI18next();
    
    return (
        <footer className={styles.footer}>
                <div className={styles.columnOne}>
                    <span> © {new Date().getFullYear()}, Film Positive</span>
                </div>
                
                
                <div className={styles.columnTwo}>
                    <span>{t("FOOTER.ADDRESS")}</span>
                </div>
                
                <div className={styles.columnThree}>
                    <span><a href="tel:+3612380200">{t("FOOTER.PHONE")}</a></span>
                </div>
                
                <div className={styles.columnFour}>
                     <div className={styles.logoContainer}>
                        <div className={styles.logo}>
                            <img src={filmpositiveLogo} alt=""/>
                        </div>
                        <div className={styles.logo}>
                            <img src={filmpositiveserviceLogo} alt=""/>
                        </div>
                        <div className={styles.logo}>
                            <img src={szupermodernLogo} alt=""/>
                        </div>
                        <div className={styles.logo}>
                            <img src={crossdotLogo} alt=""/>
                        </div>

                    </div>
                </div>
                
               
           
        </footer>
    )
    
    
}

export default Footer

